import React, { useState, Fragment, lazy, Suspense, useEffect } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import "./style.css";
import { loadCSS } from "fg-loadcss";
import jsPDF from "jspdf";
import "jspdf-autotable";
import styles from "./App.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "react-select";
import Swal from "sweetalert2";
import Resizer from "react-image-file-resizer";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Pagination from "./Pagination";
import LoadingIcon from "../images/loadingicon.gif";
import Pipelinedata from "./Pipelinedata";
import DatePicker from "react-datepicker";
import PlusincircleIcon  from "../images/plusincircle.png";
import MinusincircleIcon  from "../images/minusincircle.png";
import { Editor } from 'react-draft-wysiwyg';
import {EditorState,convertToRaw} from "draft-js";
import draftToHtml from 'draftjs-to-html';

const style = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #A0BED8",
    borderRadius: "1px",
    marginTop: "1.5%",
    backgroundColor: "#EAF4FF",
    color: "black",
    borderRadius: "2px",
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid #A0BED8",
      borderRadius: "1px",
      backgroundColor: "#EAF4FF",
      color: "black",
    },
  }),
};


const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) =>
  {
   return(
       <>
               <input 
                   type="checkbox"
                   className="submissions-checkbox"
                   ref={ref}
                   onClick={ onClick }
                   {...rest}
               />
       </>
   )
  })
const tableCustomStyles = {
  table: {
    style: {},
  },
  tableWrapper: {
    style: {
      display: "table",
    },
  },
  responsiveWrapper: {
    style: {},
  },

  expanderButton: {
    style: {
      "&:disabled": {
        display: "none",
      },
    },
  },
};

const NoticePeriod_style = {
  control: (base, state) => ({
    ...base,
    borderRadius:"2px",
   marginTop:"0.2%",
   backgroundColor:"#e8e8e8",
   color:"black",
   borderRadius:"2px",
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid #e8e8e8",
      borderRadius:"2px",
      backgroundColor:"#e8e8e8",
      color:"black"
    }
  }),
};

function ViewApplicant(id) {
  console.log(id);
  let data = window.location.pathname.slice(0, 0);
  var newURL = window.location.pathname;
  var splitURL = newURL.toString().split("/");
  var data1 = splitURL[2];
  var data2 = splitURL[3];
  var data3 = splitURL[4];
  var data5 = splitURL[5];
  var data6 = splitURL[6];
  let encodedjobid = window.btoa(id);
  let searchtxt = window.atob(data6);
  console.log(splitURL);
  window.location.href = `${data}/viewapplicant/${data1}/${data2}/${data3}/${data5}/${encodedjobid}`;
}

class InternalDB extends React.Component {
  constructor() {
    let data = window.location.pathname.slice(0, 0);
    var newURL = window.location.pathname;
    var splitURL = newURL.toString().split("/");
    var data6 = splitURL[6];
    let searchtxt = window.atob(data6);
    // console.log(searchtxt)
    super();
    this.state = {
      display: 0,

      displayform: 0,

      newjob: 0,
      jobpostingscreen: 0,
      countrydata: [],
      statedata: [],
      citydata: [],
      userstausdata: [{ value: "1", label: "Active" }],
      PrefCountryID: "",
      PrefCountryName: "",
      PrefStateID: "",
      PrefStateName: "",
      PrefCityName: "",
      PrefCityID: "",
      clientdata: [],
      HiringManager: "",
      HiringManagerid: "",
      reportingtodata: [],
      SPOCMobile: "",
      SPOCName: "",
      Assingnedid: [],
      businessunit: [{ value: "1", label: "Role" }],
      colname: "peter",
      JobTitle: "",
      ClientName: "",
      BusinessUnit: "",
      languagesdata: [],
      degreedata: [],
      ClientId: "",
      CBR_Coin: "INR",
      PR_Coin: "INR",
      PR_MinAmount: "",
      PR_MaxAmount: "",
      ClientJobID: "CJ505",
      NoofPositions: "",
      ClosedPostions: "",
      JobCreatedDate: "",
      JobClosingDate: "",
      SPOC: "",
      HiringManagerid: "",
      HiringManager: "",
      NoticePeriod: "",
      JobDescription: "",
      DegreeID: "",
      Degreename: "",
      ExperienceMin: "",
      ExperienceMax: "",
      PrimarySkills: "",
      Languages: "",
      JobStatus: "",
      SPOCMobile: "",
      SPOCName: "",
      JobCode: "",
      AssingnedID: "",
      AssingnedName: "",
      Assingnedname: [],
      ProfilePhoto: " ",
      documentscreen: 0,
      editAppliantid: "",
      personaldetailscreen: 0,
      fullnamedata: [
        { value: "1", label: "Mr." },
        { value: "2", label: "Mrs." },
      ],
      Genderdata: [
        { value: "1", label: "Male" },
        { value: "2", label: "Female" },
        { value: "3", label: "Other" },
      ],
      sourcedata: [
        { value: "1", label: "Naukri" },
        { value: "2", label: "Monster" },
        { value: "3", label: "Other" },
      ],
      docdata: [
        { value: "1", label: "PAN" },
        { value: "2", label: "Aadhar" },
        { value: "3", label: "Other" },
      ],
      ApplicantName: "",
      Gender: "",
      EmailID: "",
      AlternateEmail: "",
      MobileNo: "",
      AlternateMobile: "",
      DateofBirth: "",
      PreviousOrg: "",
      PrevDesignation: "",
      TotalExp: "",
      RelevantExp: "",
      HighestDegree: "",
      CurrentCTC: "",
      ExpectedCTC: "",
      VariableAmount: "",
      FixedAmount: "",
      Address: "",
      applicantId: [],
      SourceID: "",
      SourceName: "",
      JobTitle: "",
      Availablity: "",
      NationalityID: "",
      NationalityName: "",
      GovtIDType: "",
      GovtIDNumber: "",
      IndustryID: "",
      IndustryName: "",
      linkedInurl: "",
      KeySkills: "",
      NoticePeriod: "",
      Remarks: "",
      Honorific: "",
      Relocate: "",
      updatemsg: "",
      sourcescreen: 0,
      nationdata: [
        { value: "1", label: "Indian" },
        { value: "2", label: "Muslim" },
        { value: "3", label: "Other" },
      ],
      industrydata: [],
      original_rows: [],
      rows: [],
      search: searchtxt,
      showModal3: false,
      tagtojob: [],
      selectcheckbox: 0,
      checkboxData: [],
      editjobid: "",
      errormsg: "",
      loadingscreen: 0,
      showModal7: false,
      PipelineStatusids: [],
      PipelineStatusName: [],
      editapplicantid: "",
      totalRows: "",
      firstRow: 1,
      ShowingPageStart: "",
      ShowingPageEnd: "",
      sendEmailmodal:false,
      Emaildata:[],
      Noticeperiod : [
        {value:'0',label: "All "},
        { value: "1", label: "15 Days or less" },
        { value: "2", label: "1 Month " },
        { value: "3", label: "2 Months" },
        { value: "4", label: "3 Months" },
        { value: "5", label: "More than 3 Months" },
        { value: "6", label: "Currently Serving Notice Period" },
        { value: "7", label: "7 Days" },
        { value: "8", label: "45 Days" },
        { value: "9", label: "Any" },
        { value: "10", label: "Immediate Joiner" },
        { value: "11", label: "Maintenance" },
      ] ,
      gnoticeperiod:"",
      glocation:"",
      gtodate:"",
          fromdate:""
    };

    this.columns = [
      {
        selector: e => <>
        {e.submissioncount==0?null:<> <span style={{fontSize:13,fontSize:12,textAlign:"center",cursor:"pointer",backgroundColor:"rgb(66, 147, 254)",color:"white",fontFamily:"Inter",fontWeight:"bold",visibility:"hidden"}}> {e.submissioncount}</span></>}
                  {e.pipelinecount==0?null:<> <span style={{fontSize:13,padding:"7px",fontSize:12,textAlign:"center",cursor:"pointer",backgroundColor:"blueviolet",color:"white",fontFamily:"Inter",fontWeight:"bold",position:"absolute",visibility:"hidden"}}> {e.pipelinecount}</span></>}
                  {e.submissioncount==0?null:<> <span style={{fontSize:13,padding:"7px",fontSize:12,textAlign:"center",cursor:"pointer",backgroundColor:"rgb(66, 147, 254)",color:"white",fontFamily:"Inter",fontWeight:"bold"}}> {e.submissioncount}</span></>}
                  {e.pipelinecount==0?null:<> <span style={{fontSize:13,padding:"7px",fontSize:12,textAlign:"center",cursor:"pointer",backgroundColor:"blueviolet",color:"white",fontFamily:"Inter",fontWeight:"bold"}}> {e.pipelinecount}</span></>}</>,
        sortable: true,
      width:"5%",
      },

      {
        name: "Applicant Name",
        cell: (e) => (
          <>
            <div
              style={{ marginTop: "1.8em" }}
              onClick={() => ViewApplicant(e.id)}
              className="applicantname"
            >
              {e.ApplicantName}
            </div>
          </>
        ),

        width: "8%",
        sortable: true,
      },

      {
        name: "Email",
        selector: (row) => (
          <span className="keyskills" title={row.EmailID}>
            {row.EmailID}
          </span>
        ),
        sortable: true,
        width: "10%",
        style: {
          whiteSpace: "nowrap",

          textOverflow: "clip",

          fontFamily: "Poppins Light",
          fontWeight: "bold",
        },
      },
      {
        name: "Created Date",
        selector: "CreatedDate",
        sortable: true,
        width: "7%",
        style: {
          fontFamily: "Poppins Light",
          fontWeight: "bold",
        },
      },

      {
        name: "Mobile",
        selector: "MobileNo",
        sortable: true,
        width: "7%",
        style: {
          fontFamily: "Poppins Light",
          fontWeight: "bold",
        },
      },
      {
        name: "Skills",

        width: "11%",
        cell: (e) => (
          <>
            <span className="keyskills" title={e.KeySkills}>
              {e.KeySkills}
            </span>
          </>
        ),
        style: {
          border: "1px solid transparent",
        },
        sortable: true,
      },
      {
        name: "Exp",
        selector: "experience",
        sortable: true,
        width: "3%",
        style: {
          fontFamily: "Poppins Light",
          fontWeight: "bold",
        },
      },
      {
        name: "Current CTC",

        width: "7%",
        cell: (e) => (
          <>
            {e.CurrentCTC == null ? (
              <>
                <span
                  style={{
                    fontFamily: "Poppins Light",
                    fontWeight: "bold",
                    fontSize: 13,
                  }}
                >
                  Not Available
                </span>
              </>
            ) : (
              <>
                <span
                  style={{
                    fontFamily: "Poppins Light",
                    fontWeight: "bold",
                    fontSize: 13,
                  }}
                >
                  {e.CurrentCTC}
                </span>
              </>
            )}
          </>
        ),

        sortable: true,
        style: {
          border: "1px solid transparent",
        },
      },
      {
        name: "Expected CTC",

        width: "7%",
        cell: (e) => (
          <>
            {e.ExpectedCTC == null || e.ExpectedCTC == "" ? (
              <>
                <span
                  style={{
                    fontFamily: "Poppins Light",
                    fontWeight: "bold",
                    fontSize: 13,
                  }}
                >
                  NA
                </span>
              </>
            ) : (
              <>
                <span
                  style={{
                    fontFamily: "Poppins Light",
                    fontWeight: "bold",
                    fontSize: 13,
                  }}
                >
                  {e.ExpectedCTC}
                </span>
              </>
            )}
          </>
        ),

        sortable: true,
        style: {
          border: "1px solid transparent",
        },
      },
      {
        name: "Notice Period",
        width: "11%",

        cell: (e) => (
          <>
            {e.NoticePeriod == null || e.NoticePeriod == "" ? (
              <>
                <span
                  style={{
                    fontFamily: "Poppins Light",
                    fontWeight: "bold",
                    fontSize: 13,
                  }}
                >
                  NA
                </span>
              </>
            ) : (
              <>
                <span
                  style={{
                    fontFamily: "Poppins Light",
                    fontWeight: "bold",
                    fontSize: 13,
                  }}
                >
                  {e.NoticePeriod}
                </span>
              </>
            )}
          </>
        ),

        sortable: true,
        style: {
          border: "1px solid transparent",
        },
      },

      {
        name: "Current Location",
        width: "9%",

        cell: (e) => (
          <>
            {e.location == null ? (
              <>
                <span
                  style={{
                    fontFamily: "Poppins Light",
                    fontWeight: "bold",
                    fontSize: 13,
                  }}
                >
                  Not Available
                </span>
              </>
            ) : (
              <>
                <span
                  style={{
                    fontFamily: "Poppins Light",
                    fontWeight: "bold",
                    fontSize: 13,
                  }}
                >
                  {e.location}
                </span>
              </>
            )}
          </>
        ),
        style: {
          border: "1px solid transparent",
        },
        sortable: true,
      },
      {
        name: "Preferred Location",

        cell: (e) => (
          <>
            {e.preferred_location == null ? (
              <>
                <span className="keyskills">Not Available</span>
              </>
            ) : (
              <>
                <span className="keyskills" title={e.preferred_location}>
                  {e.preferred_location}
                </span>
              </>
            )}
          </>
        ),
        sortable: true,
        width: "15%",
        style: {
          border: "1px solid transparent",
        },
      },
    ];

    this.columns_data_for_export = this.columns
      .slice(0, this.columns.length)
      .map((d) => d.name);
    this.rows_data_for_export = this.state.rows.map((d1) =>
      this.columns
        .slice(0, this.columns.length)
        .map((d2) => d2.selector)
        .map((d3) => d1[d3])
    );
    const temp_rows = this.state.rows.map((d1) =>
      this.columns
        .slice(0, this.columns.length)
        .map((d2) => d2.selector)
        .map((d3) => d1[d3])
    );
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.do_search = this.do_search.bind(this);
    this.download_pdf = this.download_pdf.bind(this);
    this.handleAdmin = this.handleAdmin.bind(this);
    this.handlecloseAdmin = this.handlecloseAdmin.bind(this);
    this.handleBusiness = this.handleBusiness.bind(this);
    this.handleRoles = this.handleRoles.bind(this);
    this.handleTeamdata = this.handleTeamdata.bind(this);
    this.handleReport = this.handleReport.bind(this);
    this.handleUserstatus = this.handleUserstatus.bind(this);
    this.handleCountry = this.handleCountry.bind(this);
    this.hanldelState = this.handleState.bind(this);
    this.handleCity = this.handleCity.bind(this);
    this.handleReport = this.handleReport.bind(this);
    this.handleRequired = this.handleRequired.bind(this);
    this.handleIndustry = this.handleIndustry.bind(this);
    this.handleUserstatus = this.handleUserstatus.bind(this);
    this.handleCategory = this.handleCategory.bind(this);
    this.handleDisable=this.handleDisable.bind(this)
    this.handleDocument = this.handleDocument.bind(this);
    this.handleFullName = this.handleFullName.bind(this);
    this.handleTagJob = this.handleTagJob.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.selectJob = this.selectJob.bind(this);
    this.handleTag = this.handleTag.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleSubmitJob = this.handleSubmitJob.bind(this);
    this.handleSearch=this.handleSearch.bind(this)
    this.handleSubmissionsearch=this.handleSubmissionsearch.bind(this)
    this.handleNoticeperiod=this.handleNoticeperiod.bind(this)
    this.handleNoticeperiodclose=this.handleNoticeperiodclose.bind(this)
    this.handleLocationInputChange = this.handleLocationInputChange.bind(this);
  }
  handleTag(e) {
    this.setState({ editjobid: e.value, errormsg: "" });
  }
  selectJob(e, jobid) {
    var s1 = e.target.value.toString();
    var s2 = s1.split(",");
    var d1 = s2[0];
    var d2 = s2[1];
    var d3 = s2[2];

    if (e.target.checked) {
      this.setState({ selectcheckbox: 1, editapplicantid: d1 });
      this.setState(
        { checkboxData: this.state.checkboxData.concat([d1]) },
        () => {
          console.log(this.state.checkboxData);
        }
      );
      this.setState(
        { PipelineStatusName: this.state.PipelineStatusName.concat([d2]) },
        () => {
          console.log(this.state.PipelineStatusName.toString());
        }
      );
      this.setState(
        { PipelineStatusids: this.state.PipelineStatusids.concat([d3]) },
        () => {
          //console.log(this.state.PipelineStatusids)
        }
      );
    } else {
      this.setState({
        PipelineStatusName: this.state.PipelineStatusName.filter(function (
          val
        ) {
          return val !== d2;
        }),
      });
      this.setState({
        PipelineStatusids: this.state.PipelineStatusids.filter(function (val) {
          return val !== d3;
        }),
      });
      this.setState(
        {
          checkboxData: this.state.checkboxData.filter(function (val) {
            return val !== d1;
          }),
        },
        () => {
          console.log(this.state.checkboxData);
          if (this.state.checkboxData.length == 0) {
            this.setState({ selectcheckbox: 0 });
          } else {
            this.setState({ selectcheckbox: 1 });
          }
        }
      );
    }
  }
  handleNoticePeriodChange(e){
    this.setState({gnoticeperiod:e})
  }

  handleNoticeperiod(){
    this.setState({noticeperiod:1,keyGen10:[]})
  }
  handleNoticeperiodclose(){
    this.setState({noticeperiod:0})
  }
  handleOpenSendEmail=()=>{
    this.setState({ sendEmailmodal: true },()=>{
      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      const data={userid:withoutQuotes,addJobpipelinedata:"getpipesendemailids",editapplicantid:this.state.checkboxData.toString(),editjobid:1,}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getJobpipelineinfo", {
          method: "POST",
    
          body: JSON.stringify(data), // data can be `string` or {object}!
    
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
    
          .catch((error) => console.error("Error:", error))
    
          .then((response) => {
            console.log(response)
            response.result.map((e)=>{
              this.setState({Emaildata:this.state.Emaildata.concat(e.EmailID)},()=>{
                console.log(this.state.Emaildata)
              })
            })
          })
    });
  }

  closeSendEmailModal = () => {
    this.setState({ sendEmailmodal: false,Emaildata:[]})
  };

  handleLocationInputChange(event) {
    this.setState({ glocation:event.target.value });
  }

  handleDateChange(date){
    //console.log(date)
    this.setState({fromdate:date})
  }
  handleTODateChange(date){
    //console.log(date)
    this.setState({gtodate:date})
  }

  
  formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2,   
   '0'); // Months are zero-indexed
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;   
  }

  handleSubmissionsearch(){
    this.setState({loadingicon:0,sendemaildisplayscreen:false})
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    //console.log(withoutQuotes)
    //const repdata={userid:withoutQuotes,addAppliantdata:"getAppliantinfo",statuscode:1}
    const repdata={
      userid:withoutQuotes,
      addAppliantdata:"getAppliantinfo",
      page:this.state.firstRow,
      searchTerm:this.state.search,
      records:"25",
      statuscode:"1",
      gnoticeperiod:this.state.gnoticeperiod.label,
      glocation:this.state.glocation,
      fromdate:this.state.fromdate==""?"":this.formatDate(this.state.fromdate),
      gtodate:this.state.gtodate==""?"":this.formatDate(this.state.gtodate),
    }
    console.log(repdata)
    //fetch("https://www.mrecruit.in:5200/api/getApplicantdetails", {
      fetch("https://mrecruit.in/api/getApplicantdetails_v2", {
        method: "POST",

        body: JSON.stringify(repdata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
         console.log(response)
         
           let data=response.data
           if(response.data==null){
            this.setState({nodatafoundmsg:"No Data Found!",loadingicon:2})
           }else{
              data = data.map((currentValue, Index) => {
                currentValue.SNO = Index + 1;
                return currentValue;
                
              });
              this.setState({ original_rows: data, 
                rows: data,loadingicon:1,
                totalRecords:response.paging.totalRecords,
                ShowingPageStart:response.paging.ShowingPageStart,
                ShowingPageEnd:response.paging.ShowingPageEnd 
              }); 
            }
            });
  }


  handleBack() {
    let data = window.location.pathname.slice(0, 0);
    var newURL = window.location.pathname;
    var splitURL = newURL.toString().split("/");
    var data1 = splitURL[2];
    var data2 = splitURL[3];
    var data3 = splitURL[4];
    var data5 = splitURL[5];
    window.location.href = `${data}/jobposting/${data1}/${data2}/${data3}/${data5}`;
  }
  handleTagJob() {
    Swal.fire("Please Select a Applicant !");
  }

  handleFullName(e) {
    this.setState({ Honorific: e.label });
  }
  handleDocument() {
    this.setState({ documentscreen: 1 });
  }
  handleCategory(e) {
    this.setState({ CategoryName: e.label, categoryid: e.value }, () => {});
  }
  handleBusiness(e) {
    this.setState({ BusinessUnit: e.label }, () => {});
  }

  handleUserstatus(e) {
    this.setState({ cstatus: e.value });
  }
  handleIndustry(e) {
    this.setState({ Industryname: e.label, Industryid: e.value });
  }
  handleRequired(e) {
    this.setState({ ReqDocument: e.value });
  }
  handleReport(e) {
    this.setState({ ClientLeadname: e.label, ClientLeadids: e.value });
  }
  handleCity(e) {
    this.setState({ Cityname: e.label, Cityid: e.value });
  }
  openModal3 = () => {
    this.setState({ showModal3: true });
  };
  closeModal3 = () => {
    this.setState({ showModal3: false });
  };
  openModal7 = () => {
    this.setState({ showModal7: true });
  };
  closeModal7 = () => {
    this.setState({ showModal7: false });
  };
  handleSubmitJob() {
    var username = JSON.stringify(localStorage.getItem("UserId"));
    const withoutQuotes = username.replaceAll('"', "");
    const data = {
      userid: withoutQuotes,
      addJobpipelinedata: "addsubmittojob",
      editapplicantid: this.state.checkboxData.toString(),
      editjobid: this.state.editjobid,
      pipelinestatus: this.state.PipelineStatusids.toString(),
      pipelinestatusname: this.state.PipelineStatusName.toString(),
    };
    console.log(data);
    fetch("https://www.mrecruit.in:5200/api/getJobpipelineinfo", {
      method: "POST",

      body: JSON.stringify(data), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
        this.setState({ successmsg: response.message });

        setTimeout(
          function () {
            window.location.reload(false);
          }.bind(this),
          2500
        );
      });
  }
  handleUpdate() {
    if (this.state.editjobid == "") {
      this.setState({ errormsg: "Please Select a Job !" });
    } else {
      var username = JSON.stringify(localStorage.getItem("UserId"));
      const withoutQuotes = username.replaceAll('"', "");
      const data = {
        userid: withoutQuotes,
        addTaggeddata: "apptagtojob",
        editapplicantid: this.state.checkboxData.toString(),
        editjobid: this.state.editjobid,
      };
      console.log(data);
      fetch("https://www.mrecruit.in:5200/api/getTaggedinfo", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
          // console.log(response)
          this.setState({ errormsg: response.message });
          window.location.reload();
        });
    }
  }
  handleState(e) {
    this.setState({ Statename: e.label, Stateid: e.value }, () => {
      const data = { dropdownlist_input: "getcityinfo", stateid: e.value };
      console.log(data);
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
          const data = response.result;
          //console.log(json)

          const options = data.map((d) => ({
            value: d.id,
            label: d.name,
          }));

          this.setState({ citydata: options });
        });
    });
  }
  handleCountry(e) {
    this.setState({ Countryname: e.label, Countryid: e.value }, () => {
      const data = { dropdownlist_input: "getstateinfo", countryid: e.value };
      console.log(data);
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
          const data = response.result;
          //console.log(json)

          const options = data.map((d) => ({
            value: d.id,
            label: d.name,
          }));

          this.setState({ statedata: options });
        });
    });
  }

  handleCancel() {
    this.setState({ displayform: 0 });
  }
  handleRoles(e) {
    this.setState({ userrolename: e.label, userrole: e.value }, () => {
      //console.log(this.state.userrole)
    });
  }
  handleBusiness(e) {
    this.setState({ BusinessUnit: e.label }, () => {});
  }
  handleTeamdata(e) {
    this.setState({ teamname: e.label, teamids: e.value }, () => {});
  }
  handlecloseAdmin() {
    this.setState({ on: 0, adminprivileges: "No" });
  }
  handleAdmin() {
    this.setState({ on: 1, adminprivileges: "Yes" });
  }
  handleRowClicked(row) {
    //console.log(row)
    if (row.pipelinecount == 1) {
      localStorage.setItem("PipCont", row.id);
      localStorage.removeItem("SubCont");
    } else if (row.submissioncount == 1) {
      localStorage.setItem("SubCont", row.id);
      localStorage.removeItem("PipCont");
    } else if (row.pipelinecount == 1 && row.submissioncount == 1) {
      localStorage.setItem("PipCont", row.id);
      localStorage.setItem("SubCont", row.id);
    } else {
      console.log("error");
    }
  }
  handleChangerow = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    console.log(selectedRows);
  };
  openModal = (ApplicantID) => {
    this.setState({ editAppliantid: ApplicantID });
    var username = JSON.stringify(localStorage.getItem("UserId"));
    const withoutQuotes = username.replaceAll('"', "");
    const data = {
      userid: withoutQuotes,
      addAppliantdata: "getsingleApplicant",
      editAppliantid: ApplicantID,
    };
    console.log(data);
    fetch("https://www.mrecruit.in:5200/api/getApplicantdetails", {
      method: "POST",

      body: JSON.stringify(data), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
        console.log(response);
        response.result.map((e) => {
          this.setState({
            Honorific: e.Honorific,
            fullname: e.fullname,
            Gender: e.Gender,
            EmailID: e.EmailID,
            AlternateEmail: e.AlternateEmail,
            MobileNo: e.MobileNo,
            AlternateMobile: e.AlternateMobile,
            DateofBirth: e.DateofBirth,
            PreviousOrg: e.PreviousOrg,
            PrevDesignation: e.PrevDesignation,
            TotalExp: e.TotalExp,
            RelevantExp: e.RelevantExp,
            HighestDegree: e.HighestDegree,
            CurrentCTC: e.CurrentCTC,
            ExpectedCTC: e.ExpectedCTC,
            VariableAmount: e.VariableAmount,
            FixedAmount: e.FixedAmount,
            Address: e.Address,
            CountryID: e.CountryID,
            CountryName: e.CountryName,
            StateID: e.StateID,
            StateName: e.StateName,
            CityID: e.CityID,
            CityName: e.CityName,
            SourceID: e.SourceID,
            SourceName: e.SourceName,
            JobTitle: e.JobTitle,
            Availablity: e.Availablity,
            NationalityID: e.NationalityID,
            NationalityName: e.NationalityName,
            Relocate: e.Relocate,
            GovtIDType: e.GovtIDType,
            GovtIDNumber: e.GovtIDNumber,
            IndustryID: e.IndustryID,
            IndustryName: e.IndustryName,
            linkedInurl: e.linkedInurl,
            KeySkills: e.KeySkills,
            NoticePeriod: e.NoticePeriod,
            Remarks: e.Remarks,
            AppStatus: e.AppStatus,
            AppStatusnam: e.AppStatusname,
            PrefCityID: e.CityID,
            PrefCityName: e.CityName,
            PrefCountryID: e.CountryID,
            PrefCountryName: e.CountryName,
            PrefStateID: e.StateID,
            PrefStateName: e.StateName,
            ProfilePhoto: e.ProfilePhoto,
            ApplicantName: e.fullname,
          });
        });
      });

    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };
  handleSearch(){
    this.setState({loadingicon:0,sendemaildisplayscreen:false})  
    var username = JSON.stringify(localStorage.getItem("UserId"));
    const withoutQuotes = username.replaceAll('"', "");
    const repdata = {
   
      userid:withoutQuotes,
      addAppliantdata:"getAppliantinfo",
      page:this.state.firstRow,
      searchTerm:this.state.search,
      records:"25",
      statuscode:"1",
      gnoticeperiod:this.state.gnoticeperiod.label,
      glocation:this.state.glocation,
      fromdate:this.state.fromdate==""?"":this.formatDate(this.state.fromdate),
      gtodate:this.state.gtodate==""?"":this.formatDate(this.state.gtodate),

   
   
      // userid: withoutQuotes,
      // addAppsearchdata: "getAppsearchinfo",
      // getkeywordtext:search,
      // getminvalue: JSON.parse(localStorage.getItem("Minvalue")),
      // getmaxvalue: JSON.parse(localStorage.getItem("Maxvalue")),
      // getnoticeperiodname: JSON.parse(localStorage.getItem("Noticeperiod")),
      // getlocationname: JSON.parse(localStorage.getItem("Location")),
      // jobid: JSON.parse(localStorage.getItem("JobID")),
      // jobtitle: JSON.parse(localStorage.getItem("JobTitle")),
      // buttonstatus: JSON.parse(localStorage.getItem("ButtonStatus")),
      // records: "25",
      // statuscode: "1",
      // page: firstRow,
    };
    //console.log(repdata)
    fetch("https://mrecruit.in/api/getApplicantdetails_v2", {
      method: "POST",

      body: JSON.stringify(repdata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
    .then((res) => res.json())

    .catch((error) => console.error("Error:", error))

    .then((response) => {
     console.log(response)
     
       let data=response.data
       if(response.data==null){
        this.setState({nodatafoundmsg:"No Data Found!",loadingicon:2})
       }else{
          data = data.map((currentValue, Index) => {
            currentValue.SNO = Index + 1;
            return currentValue;
            
          });
          this.setState({ original_rows: data, 
            rows: data,loadingicon:1,
            totalRecords:response.paging.totalRecords,
            ShowingPageStart:response.paging.ShowingPageStart,
            ShowingPageEnd:response.paging.ShowingPageEnd 
          }); 
        }
        });
   

  }
  handlePageChange = (pageNumber) => {
    this.setState({loadingicon:0,sendemaildisplayscreen:false}) 
    var username = JSON.stringify(localStorage.getItem("UserId"));
    const withoutQuotes = username.replaceAll('"', "");
    const repdata = {
  
      userid:withoutQuotes,
      addAppliantdata:"getAppliantinfo",
      page:pageNumber,
      searchTerm:this.state.search,
      records:"25",
      statuscode:"1",
      gnoticeperiod:this.state.gnoticeperiod.label,
      glocation:this.state.glocation,
      fromdate:this.state.fromdate==""?"":this.formatDate(this.state.fromdate),
      gtodate:this.state.gtodate==""?"":this.formatDate(this.state.gtodate),

      // userid: withoutQuotes,
      // addAppsearchdata: "getAppsearchinfo",
      // getkeywordtext: this.state.search,
      // getminvalue: JSON.parse(localStorage.getItem("Minvalue")),
      // getmaxvalue: JSON.parse(localStorage.getItem("Maxvalue")),
      // getnoticeperiodname: JSON.parse(localStorage.getItem("Noticeperiod")),
      // getlocationname: JSON.parse(localStorage.getItem("Location")),
      // jobid: JSON.parse(localStorage.getItem("JobID")),
      // jobtitle: JSON.parse(localStorage.getItem("JobTitle")),
      // buttonstatus: JSON.parse(localStorage.getItem("ButtonStatus")),
      // records: "25",
      // statuscode: "1",
      // page: pageNumber,
    };
    //console.log(repdata)
    fetch("https://mrecruit.in/api/getApplicantdetails_v2", {
      method: "POST",

      body: JSON.stringify(repdata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
    .then((res) => res.json())

    .catch((error) => console.error("Error:", error))

    .then((response) => {
     console.log(response)
     
       let data=response.data
       if(response.data==null){
        this.setState({nodatafoundmsg:"No Data Found!",loadingicon:2})
       }else{
          data = data.map((currentValue, Index) => {
            currentValue.SNO = Index + 1;
            return currentValue;
            
          });
          this.setState({ original_rows: data, 
            rows: data,loadingicon:1,
            totalRecords:response.paging.totalRecords,
            ShowingPageStart:response.paging.ShowingPageStart,
            ShowingPageEnd:response.paging.ShowingPageEnd 
          }); 
        }
        });

    // const node = loadCSS(
    //   "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
    //   document.querySelector("#font-awesome-css")
    // );

    // return () => {
    //   node.parentNode.removeChild(node);
    // };
  };
  renderCustomPagination = ({
    rowsPerPage,
    onRowsPerPageChange,
    page,
    onPageChange,
  }) => {
    const totalRows = this.state.totalRecords;
    const firstRow = this.state.ShowingPageStart;
    //const lastRow = Math.min(firstRow + rowsPerPage - 1, totalRows);
    const lastRow = this.state.ShowingPageEnd;
    return (
      <div className="custom-pagination text-center">
        Showing {firstRow} - {lastRow} of {totalRows}
        <Pagination
          offset={0}
          pageCount={totalRows / 25}
          onPageChangeApplicant={this.handlePageChange}
        />
        {/* <ul className="pagination">
          {pageNumbers.map((pageNumber) => (
            <li key={pageNumber} className={pageNumber === page ? 'active' : ''}>
              {pageNumber === '...' ? (
                pageNumber
              ) : (
                <button onClick={() => this.handlePageChange(pageNumber)}>{pageNumber}</button>
              )}
            </li>
          ))}
        </ul> */}
      </div>
    );
  };

  handleCheckbox(e,row){
    //console.log(row)
    const applicantIDs = e.selectedRows.map(applicant => applicant.ApplicantID.toString());
    if(e.selectedCount>=1){
      this.setState({checkboxData: applicantIDs,sendemaildisplayscreen:true},()=>{
        //console.log(this.state.checkboxData)
      })
    }
    else if(e.selectedRows.length==0){
      this.setState({sendemaildisplayscreen:false})
    }
  }
  onEditorStateChange = (editorState) => {  
    //console.log(editorState)
     this.setState({
       editorState,
     });
     const contentState = editorState.getCurrentContent();
     const data=convertToRaw(contentState)
     const data1=draftToHtml(data)
     this.setState({maildescription:data1,successmsg:"",secondarybtn:0})
     //console.log(data1);
   };



  handleRowClicked(row){
    //console.log(row.id)
    //console.log(row)
    if(row.pipelinecount>=1 && row.submissioncount>=1){
      console.log(row)
      localStorage.setItem("PipCont",row.id)
      localStorage.setItem("SubCont",row.id)
     }
    else if(row.pipelinecount>=1){
      localStorage.setItem("PipCont",row.id)
      localStorage.removeItem("SubCont")
    }
   else if(row.submissioncount>=1){
    localStorage.setItem("SubCont",row.id)
    localStorage.removeItem("PipCont")
   }
  
   else{
    localStorage.removeItem("PipCont")
    localStorage.removeItem("SubCont")
   }
   
  }

  handleDisable(row){
    if(row.pipelinecount==0 && row.submissioncount==0){
     return true;
    
    }
    else{
     return false;
   
    }
   }
  componentDidMount() {
    var username = JSON.stringify(localStorage.getItem("UserId"));
    const withoutQuotes12 = username.replaceAll('"', "");
    const pdatadrop = {
      userid: withoutQuotes12,
      dropdownlist_input: "getjobnamelistinfo",
    };

    /* Tag To Job API Call Start */
    //const rdata111={ dropdownlist_input: "getjobnamelistinfo"}

    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(pdatadrop), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
        const data = response.result;
        //console.log(json)

        const options = data.map((d) => ({
          value: d.jobid,
          label: d.jobname,
        }));

        this.setState({ tagtojob: options });
      });
    /* Tag To Job  API Call End */

    /* Industry API Call Start */
    const rdata = { dropdownlist_input: "getindustryinfo" };
    //console.log(rdata)
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(rdata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
        const data = response.result;
        //console.log(json)

        const options = data.map((d) => ({
          value: d.id,
          label: d.name,
        }));

        this.setState({ industrydata: options });
      });
    /* Industry  API Call End */
    /* Reporting To API Call Start */
    const redata = { dropdownlist_input: "getreportinginfo" };
    //console.log(redata);
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(redata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
        const data = response.result;
        //console.log(json)

        const options = data.map((d) => ({
          value: d.reportingid,
          label: d.reportingname,
        }));

        this.setState({ reportingtodata: options });
      });

    /* Reporting To  API Call End */

    /* Language API Call Start */
    const ledata = { dropdownlist_input: "getlanguagesinfoinfo" };
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(ledata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
        const data = response.result;
        //console.log(json)

        const options = data.map((d) => ({
          value: d.id,
          label: d.name,
        }));

        this.setState({ languagesdata: options });
      });

    /*Language  API Call End */

    /* Degree API Call Start */
    const degdata = { dropdownlist_input: "getdegreesinfo" };
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(degdata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
        const data = response.result;
        //console.log(json)

        const options = data.map((d) => ({
          value: d.id,
          label: d.name,
        }));

        this.setState({ degreedata: options });
      });

    /*Language  API Call End */
    /* Country API Call Start */
    const tdata = { dropdownlist_input: "getcountryinfo" };
    console.log(tdata);
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(tdata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
        const data = response.result;
        //console.log(json)

        const options = data.map((d) => ({
          value: d.countryid,
          label: d.conuntryname,
        }));

        this.setState({ countrydata: options });
      });
    /* Country API Call  End */

    /* Client API Call Start */
    const cdata = { dropdownlist_input: "getclientDropinfo" };
    console.log(cdata);
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(cdata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
        const data = response.result;
        //console.log(json)

        const options = data.map((d) => ({
          value: d.clientid,
          label: d.clientname,
        }));

        this.setState({ clientdata: options });
      });
    /* Client API Call  End */
    const { search,firstRow }=this.state;
    this.setState({loadingicon:0})
    var username = JSON.stringify(localStorage.getItem("UserId"));
    const withoutQuotes = username.replaceAll('"', "");
    const repdata = {

      userid:withoutQuotes,
      addAppliantdata:"getAppliantinfo",
      page:this.state.firstRow,
      searchTerm:this.state.search,
      records:"25",
      statuscode:"1",
      gnoticeperiod:this.state.gnoticeperiod.label,
      glocation:this.state.glocation,
      fromdate:"",
      gtodate:"",


      // userid: withoutQuotes,
      // addAppsearchdata: "getAppsearchinfo",
      // getkeywordtext: this.state.search,
      // getminvalue: JSON.parse(localStorage.getItem("Minvalue")),
      // getmaxvalue: JSON.parse(localStorage.getItem("Maxvalue")),
      // getnoticeperiodname: JSON.parse(localStorage.getItem("Noticeperiod")),
      // getlocationname: JSON.parse(localStorage.getItem("Location")),
      // jobid: JSON.parse(localStorage.getItem("JobID")),
      // jobtitle: JSON.parse(localStorage.getItem("JobTitle")),
      // buttonstatus: JSON.parse(localStorage.getItem("ButtonStatus")),
      // records: "25",
      // statuscode: "1",
      // page: "1",
    };
    //console.log(repdata)
    fetch("https://mrecruit.in/api/getApplicantdetails_v2", {
      method: "POST",

      body: JSON.stringify(repdata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
   .then((res) => res.json())

    .catch((error) => console.error("Error:", error))

    .then((response) => {
     console.log(response)
     
       let data=response.data
       if(response.data==null){
        this.setState({nodatafoundmsg:"No Data Found!",loadingicon:2})
       }else{
          data = data.map((currentValue, Index) => {
            currentValue.SNO = Index + 1;
            return currentValue;
            
          });
          this.setState({ 
            original_rows: data, 
            rows: data,
            loadingicon:1,
            totalRecords:response.paging.totalRecords,
            ShowingPageStart:response.paging.ShowingPageStart,
            ShowingPageEnd:response.paging.ShowingPageEnd,
            loadingscreen:1
          }); 
        }
        });

    // const node = loadCSS(
    //   "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
    //   document.querySelector("#font-awesome-css")
    // );

    // return () => {
    //   node.parentNode.removeChild(node);
    // };
  }

  handleInputChange(event) {
    this.setState({ search: event.target.value });
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.do_search();
    } else {
      this.do_search();
    }
  }

  do_search() {
    const temp_rows = this.state.original_rows.filter(
      (e) => JSON.stringify(e).indexOf(this.state.search) >= 0
    );
    this.setState({ rows: temp_rows });
  }

  download_pdf() {
    /**const doc = new jsPDF();

    const temp_rows = this.state.rows.map((d1) =>
      this.columns
        .slice(0, this.columns.length)
        .map((d2) => d2.selector)
        .map((d3) => d1[d3])
    );
    doc.autoTable({
      head: [this.columns_data_for_export],
      body: temp_rows
    });
    console.log(this.columns_data_for_export, temp_rows);
    doc.save("Active_Userslists.pdf"); */
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    return (
      <>
        {/*Submit to job Modal starts */}
        {this.state.showModal7 && (
          <div class="ft-modal" id="ft-submittojob-modal" style={{ top: "0%" }}>
            <div class="ft-modal-content" style={{ width: "43%" }}>
              <div class="ft-modal-header">
                <h5
                  class="modal-title"
                  id="exampleModalLabel"
                  style={{
                    fontSize: 25,
                    fontWeight: "bold",
                  }}
                >
                  Submit To Job
                </h5>

                <hr />
              </div>
              <div className="ft-modal-body">
                <div className="text-center">
                  <span
                    style={{
                      fontFamily: "Poppins Light",
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                  >
                    Would you like to review the submission details before
                    submitting?
                  </span>
                </div>

                <div className="mt-5 row">
                  <div className="col-xl-4"></div>
                  <div className="col-xl-2">
                    {" "}
                    <button
                      className="btn updatebtn"
                      onClick={this.handleSubmitJob}
                      style={{ color: "white", marginTop: "5%", width: "100%" }}
                    >
                      Yes
                    </button>
                  </div>
                  <div className="col-xl-2">
                    {" "}
                    <button
                      className="btn updatebtn"
                      onClick={this.closeModal7}
                      style={{
                        color: "black",
                        marginTop: "5%",
                        backgroundColor: "#F3F0F0",
                        border: "1px solid",
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-xl-4"></div>
                </div>
                <div className="text-center mt-3">
                  <span
                    style={{
                      color: "#00539C",
                      fontFamily: "Poppins Light",
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.successmsg}
                  </span>
                </div>
              </div>
              <div class="ft-modal-footer">
                <a
                  class="ft-modal-close"
                  data-dismiss="modal"
                  onClick={this.closeModal7}
                >
                  <i
                    class="fa fa-times"
                    style={{ color: "black", cursor: "pointer" }}
                  ></i>{" "}
                </a>
              </div>
            </div>
          </div>
        )}
        {/**Submit to job Modal ends */}


 {/**Send Email Modal starts */}
 {this.state.sendEmailmodal && (
        <div class="ft-modal" id="ft-sendemail-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"80%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                     
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                   Send Email
                  </h5>
          
           <hr/>
            </div>
           <div className="ft-modal-body">
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="row" style={{background:"#e9ecef",padding:"1.5%"}}>
                            <div className="col-md-1">
                            <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>From</span>
                            </div>
                          <div className="col-md-11">
                          <input type="text" value="System (jobs@maintec.in)" disabled className="form-control" />
                          </div>
                       {/**   <div className="col-md-2">
                          <button style={{color:"white",marginTop:"1%",width:"100%"}} className="btn updatebtn">Send</button>
                          </div> */}
                          </div>
                          <div className="row" style={{background:"#e9ecef",padding:"1.5%"}}>
                          <div className="col-md-1">
                            <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>TO</span>
                            </div>
                          <div className="col-md-10">
                          <div class="input-group mb-3">
                            <textarea className="form-control" disabled rows="3" style={{fontSize:12}} 
                           value={this.state.Emaildata.toString()}
                            
                            >

                            </textarea>
                         
                          
                         <span  style={{color:"#007bff",fontSize:14}}>(Separate emails will be sent to each applicant)</span>
                        </div>
                           
                       
                          </div>
                          <div className="col-md-1">
                            <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13,textDecoration:"underline",color:"#4293FE",cursor:"pointer"}} onClick={this.handleBCC}>CC</span>
                            </div>

                         
                          </div>
{this.state.bccscreen==1?<>
  <div className="row" style={{background:"#e9ecef",padding:"1.5%"}}>
                          <div className="col-md-1">
                            <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>BCC</span>
                            </div>
                          <div className="col-md-10">
                          <div class="input-group mb-3">
                            <textarea className="form-control"  rows="1" style={{fontSize:12,backgroundColor:"#e9ecef"}} onChange={this.handleChange}>

                            </textarea>
                         
                          
                        </div>
                           
                       
                          </div>
                          <div className="col-md-1">
                            <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:20,textDecoration:"underline",color:"red",cursor:"pointer"}} onClick={this.handleBCCclose}><i class="fa fa-window-close" aria-hidden="true"></i></span>
                            </div>
                         
                          </div>
</>:null}

                        </div>
                       

                      </div>

                      <div className="row mt-5">
                        
                        <div className="col-xl-12">
                        <Editor
        editorState={this.state.editorState}
        onEditorStateChange={this.onEditorStateChange}    
        toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
       
        }}
      />
                        </div>

                      </div>
                      <div className="row mt-3">
                        <div className="col-xl-2">
                        <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>Attachment</span>
                        </div>
              <div className="col-xl-6" style={{border:"1px solid"}}>
                <input type="file" accept=".pdf" onChange={this.handleAttachment} name="profilepic" />

              </div>
                      </div>

                      {this.state.dummytext==""?<></>:<>
                      <div className="text-center mt-3">
<span style={{color:"#00539C",fontFamily:"Poppins Light",fontWeight:"bold"}}>{this.state.dummytext}</span>

</div>
        
                      </>}
                <div className="mt-5 row">
                      <div className="col-xl-8"></div>
                      <div className="col-xl-2">
                        {this.state.secondarybtn==0?<><button className="btn updatebtn"  style={{color:"white",marginTop:"5%",width:"100%"}} onClick={this.handleEmail}>Send</button></>:<><button className="btn updatebtn"  style={{color:"black",marginTop:"5%",backgroundColor:"#F3F0F0",border:"1px solid",width:"100%"}} disabled >Send</button></>}
                         
</div><div className="col-xl-2"> <button className="btn updatebtn" onClick={this.closeSendEmailModal} style={{color:"black",marginTop:"5%",backgroundColor:"#F3F0F0",border:"1px solid",width:"100%"}}>Cancel</button>
</div>
                     
                     </div>
<div className="text-center mt-3">
<span style={{color:this.state.successmsg=="Email Body Can't be empty."?"red":"#00539C",fontFamily:"Poppins Light",fontWeight:"bold"}}>{this.state.successmsg}</span>

</div>
           </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeSendEmailModal}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Send Email Modal ends */}


        {/**Tag To Job Modal starts */}
        {this.state.showModal3 && (
          <div class="ft-modal" id="ft-tagtojob-modal" style={{ top: "0%" }}>
            <div class="ft-modal-content" style={{ width: "43%" }}>
              <div class="ft-modal-header">
                <h5
                  class="modal-title"
                  id="exampleModalLabel"
                  style={{
                    fontSize: 25,
                    fontWeight: "bold",
                  }}
                >
                  Tag To Job
                </h5>

                <hr />
              </div>
              <div class="ft-modal-body">
                <div className="row">
                  <div className="col-xl-3">
                    <span style={{ fontFamily: "Inter", fontWeight: "bold" }}>
                      Select Job
                    </span>
                  </div>
                  <div className="col-xl-8">
                    <Select
                      options={this.state.tagtojob}
                      onChange={(e) => this.handleTag(e)}
                      components={{ IndicatorSeparator: () => null }}
                      styles={style}
                    />
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-xl-8"></div>
                  <div className="col-xl-2">
                    <button
                      className="btn updatebtn"
                      style={{ width: "100%", color: "white" }}
                      onClick={this.handleUpdate}
                    >
                      Update
                    </button>
                  </div>
                  <div className="col-xl-2">
                    <button
                      style={{ width: "100%", color: "white" }}
                      className="btn updatebtn"
                      onClick={() => this.closeModal3()}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <div className="text-center mt-5">
                  <span
                    style={{
                      color:
                        this.state.errormsg == "Please Select a Job !"
                          ? "red"
                          : "#4293FE",
                      fontFamily: "Inter",
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.errormsg}
                  </span>
                </div>
              </div>
              <div class="ft-modal-footer">
                <a
                  class="ft-modal-close"
                  data-dismiss="modal"
                  onClick={this.closeModal3}
                >
                  <i
                    class="fa fa-times"
                    style={{ color: "black", cursor: "pointer" }}
                  ></i>{" "}
                </a>
              </div>
            </div>
          </div>
        )}
        {/**Tag To Job Modal ends */}
        <div className={styles.pageContainer}>
          <div className={styles.pageBox}>
            <Row className={styles.marginBottom1}>
              <Col md={3}>
                <div class="input-group">
                  <input
                    type="text"
                    style={{ marginBottom: "2em" }}
                    class="form-control xxx"
                    placeholder="Search"
                    onChange={this.handleInputChange}
                    value={this.state.search}
                    //onKeyDown={this.handleKeyDown}
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-primary"
                      style={{ marginBottom: "2em" }}
                      type="button"
                      //onChange={this.handleInputChange}
                      onClick={this.handleSearch}
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </Col>
              {/** <Col md={2} style={{marginTop:"0.5%"}}>
              {this.state.checkboxData.length==0?<>
             
       <span className="subtext" style={{fontFamily:"Inter",fontWeight:"bold",fontSize:16}}><i class="fa fa-angle-right" aria-hidden="true" style={{fontSize:20}}></i>&nbsp;&nbsp;Submit to Job</span>
      
              </>:<>
              <a href="#ft-submittojob-modal" className="subtext" onClick={()=>this.openModal7()}>
       <span style={{fontFamily:"Inter",fontWeight:"bold",fontSize:16}}><i class="fa fa-angle-right" aria-hidden="true" style={{fontSize:20}}></i>&nbsp;&nbsp;Submit to Job</span>
       </a>
              </>}
           
            </Col>
  
            <Col md={2} style={{marginTop:"0.5%",color:"#007bff"}}>
              
              <span style={{fontFamily:"Inter",fontWeight:"bold",fontSize:16,cursor:"pointer"}} onClick={()=>window.location.reload(false)}><i class="fa fa-refresh" aria-hidden="true" style={{fontSize:17}}></i>&nbsp;&nbsp;Refresh Search</span>
                   </Col>
                   <Col md={2} style={{marginTop:"0.5%",color:"#007bff"}}>
              
              <span style={{fontFamily:"Inter",fontWeight:"bold",fontSize:16}}><i class="fa fa-filter" aria-hidden="true" style={{fontSize:17}}></i>&nbsp;&nbsp;Refine Search</span>
                   </Col> */}

<Col md={3} >
            <Select 
           // onChange={(e)=>this.handleSubmissionsearch(e)}
            options={this.state.Noticeperiod} 
            onChange={(e)=>this.handleNoticePeriodChange(e)}
            components={{  IndicatorSeparator:() => null }}
            styles={NoticePeriod_style}
            value={this.state.gnoticeperiod}
            placeholder="Select Notice Period"
            />
      </Col>

      <Col md={2} className="margin-left-css"> 
      <div class="input-group"> 
      <input type="text" class="form-control applicants-filter"  
             placeholder="Location"  
             onChange={this.handleLocationInputChange} 
             value={this.state.glocation} 
                /> 
                {/* <div class="input-group-append"> 
      <button class="btn btn-primary" style={{marginBottom:"4em"}} type="button" 
                onClick={this.handleSearch}> 
        <i class="fa fa-search"></i> 
      </button> 
    </div> */} 
      </div>       
        </Col> 
 
        <Col md={1} className="margin-left-css">
        <DatePicker  
        placeholderText="Form Date" 
        className="formdate" 
        selected={this.state.fromdate} 
        onChange={(date)=>this.handleDateChange(date)} 
        dateFormat="dd/MM/yyyy" 
        /> 
      </Col> 
      <Col md={1} style={{marginLeft:"2%"}}> 
        <DatePicker  
        placeholderText="To Date"  
        className="formdate"  
        selected={this.state.gtodate} 
        onChange={(date)=>this.handleTODateChange(date)} 
        dateFormat="dd/MM/yyyy" 
        /> 
      </Col> 
      <Col md={1} style={{marginLeft:"2%"}}> 
      <button className="btn applicant-search-btn" onClick={this.handleSubmissionsearch}> 
        Search 
      </button> 
      </Col> 

      <Col md={1} className="margin-left-css"> 
      <button className="applicant-reset-btn" onClick={this.handleReset}> 
        Clear All 
      </button> 
      </Col> 
      <Col md={4}>
          {this.state.sendemaildisplayscreen?<>
              <a href="#ft-sendemail-modal" onClick={()=>this.handleOpenSendEmail()}>
             <span 
             className="send-emailtext-applicants">Send Email</span>
             </a>
            </>:<>
          
            </>}         
      </Col>
     
              <Col md={4} style={{ marginTop: "0.5%", color: "#007bff" }}>
                {this.state.checkboxData.length == 0 ? ( 
                  <> 
                    <span className="tagtojobtxt" onClick={this.handleTagJob} style={{display: 
                            this.state.loadingscreen == 0 ? "none" : null}}> 
                      <i 
                        class="fa fa-tag" 
                        aria-hidden="true" 
                        style={{ 
                          fontSize: 17, 
                        }} 
                      ></i> 
                      &nbsp;&nbsp;Tag to Job 
                    </span> 
                  </> 
                ) : ( 
                  <> 
                    {" "} 
                    <a 
                      href="#ft-tagtojob-modal" 
                      className="tagtojobtxt" 
                      onClick={() => this.openModal3()} 
                    > 
                      <i
                        class="fa fa-tag"
                        aria-hidden="true"
                        style={{
                          fontSize: 17,
                          display:
                            this.state.loadingscreen == 0 ? "none" : null,
                        }} 
                      ></i> 
                      &nbsp;&nbsp;Tag to Job 
                    </a> 
                  </> 
                )} 
              </Col> 
              <div className="col-xl-2" style={{ textAlign: "end" }}> 
                <button 
                  className="btn updatebtn" 
                  style={{ color: "white", width: "50%", marginTop: "2%" }} 
                  onClick={this.handleBack} 
                > 
                  Back 
                </button> 
              </div> 
            </Row> 

            <div 
              className={styles.clientContainer_old} 
              style={{ marginTop: "1%" }} 
            >
              <div className={styles.tableContainer}> 
                {this.state.loadingscreen == 0 ? ( 
                  <> 
                    <img 
                      src={LoadingIcon} 
                      style={{ marginLeft: "42em", width: "6%" }} 
                    /> 
                  </> 
                ) : ( 
                  <></> 
                )} 
               {this.state.loadingicon==1?<> 
            <DataTable 
                paginationComponent={this.renderCustomPagination} 
                paginationPerPage={25} 
                columns={this.columns} 
                data={this.state.rows} 
                pagination 
                striped 
                dense 
                expandableRows 
                selectableRows={true} 
                selectableRowsHighlight 
                onSelectedRowsChange={(e,row)=>this.handleCheckbox(e,row)} 
                selectableRowsComponent={Checkbox} 
                expandableRowsComponent={<Pipelinedata/>} 
                onRowClicked={this.handleRowClicked} 
                expandableRowDisabled={this.handleDisable } 
                customStyles={tableCustomStyles} 
                expandOnRowClicked 
                responsive 
                onRowExpandToggled={( row,e) => {    
                if(e.pipelinecount>=1 && e.submissioncount>=1){ 
                localStorage.setItem("PipCont",e.id) 
                localStorage.setItem("SubCont",e.id) 
               } 
              else if(e.pipelinecount>=1){ 
                localStorage.setItem("PipCont",e.id) 
                localStorage.removeItem("SubCont") 
              } 
             else if(e.submissioncount>=1){ 
              localStorage.setItem("SubCont",e.id) 
              localStorage.removeItem("PipCont") 
             } 
               
             else{    
              localStorage.removeItem("PipCont") 
              localStorage.removeItem("SubCont") 
             } }} 
                expandableIcon={{ 
                  collapsed: 
                  
                 <img   src={`${PlusincircleIcon}`}  
                  style={{width:"60%"}} />, 
                  expanded: <img src={MinusincircleIcon} style={{width:"60%"}} />, 
                   
                }}  
               
              />
           </>:null
          }   
             {this.state.loadingicon==2?<>
             <br/><br/><br/>
            <span className="nodatafound-msg">{this.state.nodatafoundmsg}</span>
            <br/><br/><br/><br/>
           </>:null
          }
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default InternalDB;